<template>
  <v-text-field
    :outlined="outlined"
    :dense="dense"
    v-model="formattedValue"
    :label="label"
    :disabled="disabled"
    :readonly="readonly"
    :required="required"
    :rules="rules"
    type="number"
    step=".01"
    @blur="
      format();
      isFocused = false;
    "
    @focus="isFocused = true"
    :hint="hint"
    :persistent-hint="!!hint"
    class="amount-text-field"
  >
    <span slot="append" class="pt-1 font-weight-black">
      CHF
    </span>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      required: true
    },
    label: {
      required: true,
      type: String
    },
    outlined: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    showLimitHint: {
      type: Boolean,
      default: false
    },
    rules: {
      default: () => []
    }
  },

  data: () => ({
    internal: 0,
    isFocused: false,
    hint: null
  }),

  mounted() {
    this.internal = this.parseIncoming(this.value);
  },

  watch: {
    value(val) {
      if (!this.isFocused) {
        this.internal = this.parseIncoming(val);
      }
    }
  },

  computed: {
    formattedValue: {
      get() {
        return this.internal;
      },
      set(value) {
        this.internal = value;
        this.$emit("input", parseFloat(this.parseOutgoing()) * 100);
      }
    }
  },

  methods: {
    /**
     * Format our internal number to decimal.
     */
    format() {
      this.internal = this.parseOutgoing();
    },

    /**
     * Parse outgoing number.
     */
    parseOutgoing() {
      let parsedNumber = 0;

      if ((parsedNumber = parseFloat(this.internal))) {
        this.hint = null;
        return parsedNumber.toFixed(2);
      }

      if (this.showLimitHint) {
        this.hint = "Keine Begrenzung!";
      }
      return 0;
    },

    /**
     * Parse incoming number.
     */
    parseIncoming(number) {
      if (number == 0) {
        if (this.showLimitHint) {
          this.hint = "Keine Begrenzung!";
        }
        return 0;
      }
      this.hint = null;
      return (number / 100).toFixed(2);
    }
  }
};
</script>

<style>
.amount-text-field .v-text-field__details .v-messages {
  color: var(--v-primary-base);
  display: block;
}
</style>
