var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"catering-products mt-4 ml-8 mr-8"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"display-2 mb-5"},[_vm._v("Produkte")])]),_c('v-col',{staticClass:"text-sm-right"},[_c('tooltip-button',{attrs:{"icon":"add","text":"Produkt erfassen","color":"primary darken-1","classNames":"ml-4 float-right","position":"top"},on:{"click":function($event){return _vm.openCreateDialog()}}})],1)],1),_c('v-card',{staticClass:"elevation-0",attrs:{"color":"default","outlined":""}},[_c('v-card-text',[_c('remote-data-table',{ref:"cateringProductsDatatable",attrs:{"url":'backoffice/catering-products',"search-enabled":true,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.catering_category.name)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" CHF "+_vm._s(_vm._f("formatCurrency")(item.price))+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount)+"% ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("clear")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"elevation-0 ml-3 mt-1 mb-1",attrs:{"x-small":"","color":"default","fab":""},on:{"click":function($event){return _vm.openEditDialog(item.id)}}},[_c('v-icon',[_vm._v(" edit ")])],1),_c('v-btn',{staticClass:"elevation-0 ml-1 mt-1 mb-1",attrs:{"x-small":"","color":"default","fab":""},on:{"click":function($event){return _vm.openDeleteDialog(item.id, item.name)}}},[_c('v-icon',[_vm._v(" delete ")])],1)]}}])})],1)],1)],1),_c('catering-product-dialog',{ref:"cateringProductDialog",on:{"saved":function($event){return _vm.$refs.cateringProductsDatatable.getDataFromApi()}}}),_c('delete-dialog',{ref:"deleteDialog",on:{"deleted":function($event){return _vm.$refs.cateringProductsDatatable.getDataFromApi()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }