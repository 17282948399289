<template>
  <div class="catering-products mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">Produkte</h1>
        </v-col>
        <v-col class="text-sm-right">
          <tooltip-button
            icon="add"
            text="Produkt erfassen"
            color="primary darken-1"
            @click="openCreateDialog()"
            classNames="ml-4 float-right"
            position="top"
          />
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            :url="'backoffice/catering-products'"
            :search-enabled="true"
            :headers="headers"
            ref="cateringProductsDatatable"
          >
            <template v-slot:item.category="{ item }">
              {{ item.catering_category.name }}
            </template>
            <template v-slot:item.price="{ item }">
              CHF {{ item.price | formatCurrency }}
            </template>
            <template v-slot:item.discount="{ item }">
              {{ item.discount }}%
            </template>
            <template v-slot:item.is_active="{ item }">
              <v-icon v-if="item.is_active" color="success">
                check
              </v-icon>
              <v-icon v-else color="error">clear</v-icon>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn
                x-small
                class="elevation-0 ml-3 mt-1 mb-1"
                @click="openEditDialog(item.id)"
                color="default"
                fab
              >
                <v-icon>
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                x-small
                class="elevation-0 ml-1 mt-1 mb-1"
                @click="openDeleteDialog(item.id, item.name)"
                color="default"
                fab
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <catering-product-dialog
      ref="cateringProductDialog"
      @saved="$refs.cateringProductsDatatable.getDataFromApi()"
    />
    <delete-dialog
      ref="deleteDialog"
      @deleted="$refs.cateringProductsDatatable.getDataFromApi()"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import TooltipButton from "@/components/forms/TooltipButton";
import RemoteDataTable from "@/components/RemoteDataTable";
import CateringProductDialog from "@/components/dialogs/CateringProductDialog";
import DeleteDialog from "@/components/dialogs/DeleteDialog";

export default {
  props: {
    config: {
      type: Object
    }
  },

  components: {
    TooltipButton,
    RemoteDataTable,
    CateringProductDialog,
    DeleteDialog
  },

  data: () => ({
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      { text: "Name", align: "left", sortable: true, value: "name" },
      { text: "Kategorie", align: "left", sortable: true, value: "category" },
      { text: "Preis", align: "left", sortable: true, value: "price" },
      { text: "Rabatt", align: "left", sortable: true, value: "discount" },
      { text: "Verfügbar", align: "left", sortable: true, value: "is_active" },
      {
        text: "Aktionen",
        align: "right",
        sortable: false,
        value: "action",
        width: 150
      }
    ],
    search: null
  }),

  watch: {},

  computed: {
    ...mapState("app", ["configs"])
  },

  mounted() {
    this.$nextTick(() => {});
  },

  methods: {
    /**
     * Open catering categories dialog.
     */
    openCateringCategoriesDialog() {
      this.$refs.cateringCategoriesDialog.open = true;
    },

    /**
     * Open create dialog
     */
    openCreateDialog() {
      this.$refs.cateringProductDialog.mode = "create";
      this.$refs.cateringProductDialog.open = true;
    },

    /**
     * Open edit dialog
     */
    openEditDialog(id) {
      this.$refs.cateringProductDialog.mode = "edit";
      this.$refs.cateringProductDialog.cateringProductId = id;
      this.$refs.cateringProductDialog.open = true;
    },

    /**
     * Open delete dialog
     */
    openDeleteDialog(id, name) {
      this.$refs.deleteDialog.entityId = id;
      this.$refs.deleteDialog.entityName = `#${id} ${name}`;
      this.$refs.deleteDialog.entityUrl = "catering-products";
      this.$refs.deleteDialog.open = true;
    },

    /**
     * Refetch configs.
     */
    refetchConfigs() {
      this.$store.commit("app/fetchInitialData", this.$api);
    }
  }
};
</script>
