<template>
  <v-dialog v-model="open" scrollable width="700">
    <v-form v-model="valid" v-if="cateringProduct" @submit.prevent="save()">
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="mode === 'create'">Produkt erfassen</span>
          <span v-if="mode === 'edit'">Produkt bearbeiten</span>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="7">
              <v-text-field
                outlined
                dense
                v-model="cateringProduct.name"
                label="Name"
                type="text"
                :rules="textRules"
                :maxlength="255"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="cateringProduct.description"
                label="Beschreibung"
                type="text"
                :maxlength="255"
              ></v-text-field>
              <v-select
                outlined
                dense
                :items="categories"
                v-model="cateringProduct.catering_category.id"
                label="Kategorie"
                item-text="name"
                item-value="value"
                :rules="textRules"
              ></v-select>
            </v-col>
            <v-col cols="5">
              <div class="mt-6">
                <v-switch
                  dense
                  v-model="cateringProduct.is_active"
                  :label="
                    cateringProduct.is_active ? 'Verfügbar' : 'Nicht verfügbar'
                  "
                ></v-switch>
              </div>
              <AmountTextField v-model="cateringProduct.price" label="Preis" />
              <v-text-field
                outlined
                dense
                v-model="cateringProduct.discount"
                label="Rabatt in %"
                type="number"
                :min="0"
                :max="100"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="abort()">Abbrechen</v-btn>
          <v-btn color="primary" text type="submit" :disabled="!valid">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import AmountTextField from "@/components/forms/AmountTextField";

export default {
  components: { AmountTextField },

  data: () => ({
    open: false,
    mode: "create",
    valid: false,

    cateringProductId: null,
    cateringProduct: null,

    textRules: [v => !!v || "Muss ausgefüllt sein"],
    emailRules: [
      v => (!!v && /.+@.+\..+/.test(v)) || "E-Mail muss gültig sein"
    ],
    numericRules: [v => parseInt(v) > 0 || "Muss eine Nummer > 0 sein"]
  }),

  watch: {
    open(value) {
      if (value) {
        if (this.mode === "create") {
          this.resetForm();
        } else if (this.mode === "edit") {
          this.fetchCateringProduct();
        }
      }
    }
  },

  computed: {
    ...mapState("app", ["configs"]),

    categories() {
      return this.configs.catering_categories.map(cat => ({
        name: cat.name,
        value: cat.id
      }));
    }
  },

  methods: {
    /**
     * Reset form.
     */
    resetForm() {
      this.cateringProduct = {
        id: null,
        name: null,
        price: 0,
        catering_category: {}
      };
    },

    /**
     * Fetch catering product from server
     */
    fetchCateringProduct() {
      this.$api.http
        .get(`backoffice/catering-products/${this.cateringProductId}`)
        .then(response => {
          this.cateringProduct = response.data;
        });
    },

    /**
     * Close modal
     */
    abort() {
      this.open = false;
    },

    /**
     * Save catering product.
     */
    save() {
      this.cateringProduct.catering_category_id = this.cateringProduct.catering_category.id;
      if (this.mode === "create") {
        this.$api.http
          .post(`backoffice/catering-products`, this.cateringProduct)
          .then(response => {
            this.open = false;
            this.$emit("saved");
          })
          .catch(error => {
            const message = error.response.data.message;
            this.$toast.error(message);
          });
      } else if (this.mode === "edit") {
        this.$api.http
          .put(
            `backoffice/catering-products/${this.cateringProductId}`,
            this.cateringProduct
          )
          .then(response => {
            this.open = false;
            this.$emit("saved");
          })
          .catch(error => {
            const message = error.response.data.message;
            this.$toast.error(message);
          });
      }
    }
  }
};
</script>
